import { addItemToCollection, deepCopy, deepCopyAndApplyReplacements } from '../controls/forms/formFunctions';
import HubFormGenerator from '../controls/hub/HubFormGenerator';
import { allDataLoaded, hideAlert, pageChanged, setDataStore, setError, showAlert, updateForm } from '../redux/actions';
import { addFormEvent, Alert, decodeDisabled, encodeUrl, findForm, parseBool, showModalWrapper } from './globalMethods';
import { substitute } from './stringsubstitutions';
import { getValueFromObjectUsingPath, getValueFromStoresUsingPath } from './formDataServices';
import AuthB2C from './AuthB2c';
import { appSettings } from './settings';

export class RunActionsRequest {
    constructor(formGenerator, field, action, value, formData, collectionStack = [], loadingEvents = false) {
        this.formGenerator = formGenerator;
        this.field = field;
        this.action = action;
        this.value = value;
        this.formData = formData;
        this.collectionStack = collectionStack;
        this.loadingEvents = loadingEvents;
    }
}

// Event handlers
export const runActions = function (runActionsRequest) {
    var { formGenerator, field, action, value, formData, collectionStack, loadingEvents } = runActionsRequest;

    if (action == null) return;

    var self = formGenerator;

    // Clear any form errors
    formGenerator.props.controller.props.dispatch(hideAlert());

    if (!formData.playBack) {
        var eventData = { property: field.property, type: field.type, value, action, field };
        addFormEvent(formGenerator.state.form, formData, 'action', eventData, field);
    }

    var actions = formGenerator.getActionsFromProperty(action);
    if (actions.length === 0) {
        //   Alert('No actions found');
    }

    var fieldCopy = deepCopy(field);
    var val = value;
    if (field.replacements == null) field.replacements = {};

    field.replacements = { ...field.replacements, VALUE: val };

    actions = deepCopyAndApplyReplacements(actions, field.replacements);

    var lastAddedField = null;
    var lastAddedFieldIndex = 0;

    actions.forEach((singleAction) => {
        try {
            if (singleAction.toString().startsWith('${')) {
                singleAction = substitute(singleAction, formGenerator.props.controller, [
                    //    { value: value, row: collectionStack[0] }, removed due to services page
                    { value: value },
                    { collectionStack: collectionStack },
                    ...collectionStack,
                    formData,
                ]);

                if (singleAction === '') return;
            }
        } catch {
            debugger;
        }

        if (singleAction.disabled != null) {
            var disabled = formGenerator.decode(field, singleAction.disabled, true, val, collectionStack);
            if (disabled) return;
        }
        if (singleAction.type == null) debugger;
        switch (singleAction.type.toLowerCase()) {
            // TODO remove quote pageId
            case 'uploadevents':
                var eventObj = {
                    pageInfo: {
                        new: false,
                        pageId: 'Quote',
                        events: JSON.parse(formData[singleAction.key]),
                    },
                };

                formGenerator.props.controller.props.dispatch(setDataStore(formGenerator.props.controller, eventObj));
                break;

            case 'addtocollection':
                if (self.state.stillLoading) {
                    return;
                }

                if (singleAction.replacements == null) {
                    singleAction.replacements = {};
                }

                singleAction.replacements = { ...singleAction.replacements, VALUE: val };

                var formItem = val;
                if (singleAction.settings.formSection != null) {
                    formItem = { type: 'formSection', key: singleAction.settings.formSection };
                    formItem.replacements = singleAction.replacements;
                }

                lastAddedField = addItemToCollection(formGenerator, singleAction.settings.container, formItem, formData);
                var formIsValid = formGenerator.validateForm(formData);

                lastAddedFieldIndex = lastAddedField.index;
                addFormEvent(formGenerator.state.form, formData, 'reloadPage', {});
                addFormEvent(formGenerator.state.form, formData, 'addToCollection', { field: fieldCopy, value: val });
                formData.formIsValid = formIsValid;
                break;

            case 'request':
                /// TODO.
                // Create admin web site to create blank apps and azure functions (2 types) blobs  with versions and upgrade options, also maybe build integration and environments
                // 1.  CREATE SINGLE Request method
                // if no content auto refresh
                // If data auto add to data and form stores
                // Add setting that stops noth doing Anything
                if (formData.playBack) return;

                requestAction(
                    singleAction.request,
                    formGenerator,
                    singleAction,
                    formGenerator.props.controller,
                    val,
                    collectionStack,
                    formData,
                    lastAddedFieldIndex,
                    self,
                    field
                );

                break;
            // case 'refreshpage':
            //     self.updateFormData(formData);
            //     formGenerator.props.dispatch(allDataLoaded(false));
            //     formGenerator.props.dispatch(allDataLoaded());
            //     break;
            case 'resetform':
                formData = {};
                break;
            case 'setintellisense':
                var intellisense = formGenerator.props.controller.props.intellisense;
                intellisense.environments[val.intellisenseId] = {
                    suggestions: [],
                    strategySuggestions: [],
                    connectorSuggestions: [],
                    replacementSuggestions: [],
                    pipeSuggestions: [],
                    configs: {},
                };

                var environment = intellisense.environments[val.intellisenseId];

                val.configItems.forEach((item) => {
                    environment.configs[item.name] = item.config;
                });

                return;
            case 'setproperties':
                var obj = null;
                var controller = formGenerator.props.controller;

                var settings = singleAction.settings;
                var mergeDataStore = false;
                if (singleAction.settings.values) {
                    mergeDataStore = parseBool(singleAction.settings.mergeDataStore, false);
                    settings = singleAction.settings.values;
                }

                //    if (collectionStack.length > 0) collectionStack.unshift({ row: collectionStack[0] });

                for (var propName in settings) {
                    console.log(propName);
                    var value = settings[propName];
                    if (value === 'null') value = null;

                    value = substitute(value, controller, [
                        //    { value: value, row: collectionStack[0] }, removed due to services page
                        { value: value },
                        { collectionStack: collectionStack },
                        ...collectionStack,
                        formData,
                    ]);

                    if (propName.indexOf('ds.') === 0) {
                        if (obj == null) obj = {};
                        var key = propName.substring(3);
                        var key1 = substitute(key, controller, [{ value: value }, ...collectionStack, formData]);

                        const paths = key1.split('.');
                        var newObj = obj;
                        while (paths.length > 0) {
                            var newKey = paths.shift();
                            if (newObj.hasOwnProperty(newKey)) {
                                if (paths.length !== 0) newObj = newObj[newKey];
                                else {
                                    newObj[newKey] = value;
                                }
                            } else {
                                if (paths.length !== 0) {
                                    newObj[newKey] = {};
                                    newObj = newObj[newKey];
                                } else {
                                    newObj[newKey] = value;
                                }
                            }
                        }

                        // obj[key1] = value;
                    } else {
                        var newPropName = substitute(propName, controller, [{ value: value }, ...collectionStack, formData]);
                        formData[newPropName] = value;
                    }
                }

                if (obj != null) {
                    controller.props.dispatch(setDataStore(controller, obj, false, mergeDataStore));
                }

                if (singleAction.setState) {
                    self.updateFormData(formData);
                }

                break;

            case 'navigateto':
                var url = substitute(singleAction.url, formGenerator.props.controller, [
                    singleAction,
                    singleAction.settings,
                    formData,
                    val,
                ]);

                if (url.toLowerCase() === window.location.pathname.toLowerCase() + window.location.search.toLowerCase()) break;

                url = encodeUrl(url);

                var newData = {};
                newData[formGenerator.state.form.id] = formGenerator.getFormData();

                var sds = setDataStore(formGenerator.props.controller, {}, true);
                self.props.controller.props.dispatch(sds);

                if (singleAction.url == null) {
                    Alert('navigateTo missing action.url');
                    return;
                }

                self.props.controller.props.dispatch(pageChanged(url));
                self.props.controller.props.history.push(url);
                break;
            case 'action':
            case 'callaction':
                var fieldCopy = deepCopy(field);
                fieldCopy.replacements = { ...fieldCopy.replacements, ...singleAction.replacements };
                formGenerator.runActions(fieldCopy, singleAction.key, val, formData, collectionStack);
                break;
            // case 'pageDataLoaded':
            //     break;

            case 'setState':
                self.setState(self.state);
                break;
            case 'closeform':
                self.props.controller.props.dispatch(updateForm(null));
                break;
            case 'clearform':
                formData = {};
                break;

            case 'logout':
                if (appSettings.ADAL_B2C) {
                    const auth = new AuthB2C();
                    auth.logout();
                }

                break;
            case 'reloadpage':
                window.location.reload();
                break;
            case 'refreshpage':
            case 'refresh':
                var parentFormGenerator =
                    //  formGenerator.props.parentFormGenerator?.props?.parentFormGenerator ??
                    formGenerator.props.parentFormGenerator ?? formGenerator;
                parentFormGenerator.refresh(parentFormGenerator.props.controller);
                break;
            // case 'updateresource':
            //     // var copyStack = deepCopy(collectionStack);
            //     // copyStack.reverse();
            //     // singleAction = substitute(singleAction, formGenerator.props.controller, [...copyStack, self.getFormData()]);
            //     // self.props.dispatch(updateResource(singleAction.settings));

            //     break;
            case 'javascript':
                if (window[singleAction.key]) {
                    var settings = substitute(singleAction.settings, formGenerator.props.controller, [
                        ...collectionStack,
                        formGenerator.getFormData(),
                    ]);

                    var javascriptRequest = {
                        hubFormGenerator: formGenerator,
                        controller: formGenerator.props.controller,
                        formData: formGenerator.getFormData(),
                        dataStore: formGenerator.props.dataStore,
                        field,
                        value: val,
                        settings,
                        functions: {
                            substitute,
                            setDataStore: (data) =>
                                formGenerator.props.dispatch(setDataStore(formGenerator.props.controller, data)),
                            deepCopy,
                            getValueFromStoresUsingPath,
                            getValueFromObjectUsingPath,
                        },
                    };

                    window[singleAction.key](javascriptRequest);
                    break;
                }

                // Missing conversion method
                debugger;
                break;

            case 'jmethod':
            case 'conversion':
                if (window[singleAction.key]) {
                    var settings = substitute(singleAction.settings, formGenerator.props.controller, [
                        ...collectionStack,
                        formGenerator.getFormData(),
                    ]);
                    window[singleAction.key](formGenerator, formData, field, val, settings, collectionStack);
                    break;
                }

                // Missing conversion method
                debugger;
                break;

            case 'alert':
                var message = substitute(singleAction.message, formGenerator.props.controller, [
                    formGenerator.getFormData(),
                    ...collectionStack,
                ]);

                if (typeof message === 'object' && message !== null) message = JSON.stringify(message, null, 3);

                alert(message);
                break;

            case 'form':
                if (!loadingEvents) {
                    // TODO doesn't work
                    formGenerator.clearForm();

                    var form = deepCopy(findForm(formGenerator.props.controller.props.page, singleAction.key));
                    var formSettings2 = { ...singleAction.settings, ...form.settings, parentFormGenerator: formGenerator };
                    var formSettings = substitute(formSettings2, formGenerator.props.controller, [
                        formGenerator.getFormData(),
                        ...collectionStack,
                    ]);
                    var copiedAction = deepCopy(singleAction);
                    copiedAction.settings = formSettings;

                    var newForm = {
                        title: copiedAction.settings
                            ? substitute(form.title, formGenerator.props.controller, [formSettings, ...collectionStack])
                            : form.title,
                        description: copiedAction.settings
                            ? substitute(form.description, formGenerator.props.controller, [formSettings, ...collectionStack])
                            : form.description,
                        settings: { position: 'right', ...formSettings },
                        class: form.class,
                        styles: form.styles,
                        html: (
                            <HubFormGenerator
                                controller={formGenerator.props.controller}
                                config={formGenerator.props.config}
                                layoutItem={copiedAction}
                            />
                        ),
                        open: true,
                    };

                    self.props.controller.props.dispatch(updateForm(newForm));
                }
                break;
            default:
                debugger;
                Alert('unable to find action:' + singleAction.type.toLowerCase());
                break;
        }
    });

    self.updateFormData(formData);
    formGenerator.props.dispatch(allDataLoaded(false));
    formGenerator.props.dispatch(allDataLoaded());
};

function requestAction(
    request,
    formGenerator,
    singleAction,
    controller,
    val,
    collectionStack,
    formData,
    lastAddedFieldIndex,
    self,
    field
) {
    if (decodeDisabled(request, request.disabled, controller, self.getDataStore(), self.getFormData())) {
        return;
    }

    var requestFormData = {};
    if (
        (request.body != null && request.body.toString().includes('${form}')) ||
        (request.body != null && request.body.toString().includes('${form.'))
    ) {
        requestFormData = formGenerator.getFormattedFormData();
        // } else if (request.body != null) {
        //     requestFormData = substitute(request.body, controller, [{ value: val }, ...collectionStack, formData]);
        // } else {
        //     requestFormData = formGenerator.getFormattedFormData();
    }

    var req = deepCopy(singleAction);
    if (parseBool(req.request.useSubstitutions, false)) {
        req = substitute(req, controller, [{ value: val }, ...collectionStack, formData]);
    } else {
        req.request.url = substitute(request.url, controller, [{ value: val }, ...collectionStack, formData]);
    }

    const makeRequestFunc = (closeModal) =>
        formGenerator.props.controller.makeRequest(
            req,
            false,
            {
                ...formData,
                ...formGenerator.getFormattedFormData(),
                form: requestFormData,
                value: val,
                index: lastAddedFieldIndex.toString(),
                formData: formGenerator.getFormData(),
            },
            (data) => {
                if (request.isDownload) {
                    // controller.props.dispatch(allDataLoaded(false));
                    // // var  downloadPDF => (pdf) {
                    // const linkSource = `data:application/pdf;base64,${data}`;
                    // const downloadLink = document.createElement('a');
                    // const fileName = 'abc.pdf';
                    // downloadLink.href = linkSource;
                    // downloadLink.download = fileName;
                    // downloadLink.click();
                    // downloadLink.remove();
                    // controller.props.dispatch(setDataStore(controller, { xxx: true }));
                    // self.setState(self.state);
                    // controller.props.dispatch(allDataLoaded());
                }
                // }
                var mergeData = () => {
                    // controller.props.dispatch(allDataLoaded(false));
                    // var newFormData2 = {};
                    // formGenerator.AddTo(self.getDataStore(), newFormData2, 'mergeFormData');
                    // self.updateFormData({ ...self.getFormData(), ...newFormData2 });
                    // controller.props.dispatch(allDataLoaded());}
                };

                if (request.storeAt != null) {
                    switch (request.storeAt) {
                        case 'formData':
                            break;
                        case 'mergeFormData':
                            break;
                        default:
                            var storeAt = substitute(request.storeAt, controller, [data, { value: val }, formData]);
                            var obj = {};
                            obj[storeAt] = data;
                            addFormEvent(formGenerator.state.form, self.getFormData(), 'setDataStore', { value: obj });
                            controller.props.dispatch(setDataStore(controller, obj));
                    }
                } else {
                    if (data != null) {
                        addFormEvent(formGenerator.state.form, self.getFormData(), 'setDataStore', { value: data });
                        controller.props.dispatch(setDataStore(controller, data, false, parseBool(request.mergeResult, false)));
                    }
                }

                if (data && data['formData'] != null) {
                    debugger;
                    controller.props.dispatch(allDataLoaded(false));
                    var newFormData = {};
                    formGenerator.AddTo(self.getDataStore(), newFormData, 'formData');
                    self.updateFormData(newFormData);
                    controller.props.dispatch(allDataLoaded());
                }

                if (data && data['mergeFormData'] != null) {
                    debugger;
                    controller.props.dispatch(allDataLoaded(false));
                    var newFormData2 = {};
                    formGenerator.AddTo(self.getDataStore(), newFormData2, 'mergeFormData');
                    self.updateFormData({ ...self.getFormData(), ...newFormData2 });
                    controller.props.dispatch(allDataLoaded());
                }

                if (request.onSuccess) {
                    self.props.dispatch(setError());
                    self.runActions(field, request.onSuccess, data, self.getFormData(), collectionStack);
                }

                closeModal();
            },
            (error) => {
                if (request.onFailure) {
                    self.runActions(field, request.onFailure, error, self.getFormData(), collectionStack);
                } else {
                    self.props.controller.props.dispatch(setError(error));
                }
                closeModal();
            }
        );

    if (request.method === 'GET' || request.disableSpinner === true) {
        makeRequestFunc(() => {});
        return;
    }

    showModalWrapper(self.props.controller, (closeModal) => makeRequestFunc(closeModal), request.spinnerText || '');
}
