import axios from 'axios';
import { getToken, aquireToken, storeRedirectUrl } from '../adal';
import { encodeUrl } from './globalMethods';
import { appSettings } from './settings';

axios.defaults.baseURL = appSettings.BASE_URL;

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (!error.response) {
            return Promise.reject(error);
        }

        const { response: errorResponse } = error;

        if (errorResponse.status === 401 && errorResponse.config && !errorResponse.config.__isRetryRequest) {
            try {
                // Acquire a new token
                const newToken = await aquireToken(true);

                // Update the global Axios headers with the new token
                axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;

                // Mark the request as a retry
                errorResponse.config.__isRetryRequest = true;

                // Update the request's Authorization header with the new token
                errorResponse.config.headers['Authorization'] = `Bearer ${newToken}`;

                // Retry the original request
                return axios(errorResponse.config);
            } catch (err) {
                storeRedirectUrl();
                return Promise.reject(err);
            }
        }

        if (errorResponse.status === 401) {
            storeRedirectUrl();
            if (appSettings.ADAL_B2C) {
                // const auth = new AuthB2C();
                // auth.login();
            } else {
                // // authContext.login();
            }
        }

        return Promise.reject(error);
    }
);

const getDefaultHeaders = () => ({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getToken()}`,
});

export const makeAxiosPostRequest = (url, data = null, headers) => makeAxiosRequest('POST', url, data, headers);
export const makeAxiosPutRequest = (url, data = null, headers) => makeAxiosRequest('PUT', url, data, headers);
export const makeAxiosPatchRequest = (url, data = null, headers) => makeAxiosRequest('PATCH', url, data, headers);
export const makeAxiosGetRequest = (url, headers) => makeAxiosRequest('GET', url, null, headers);
export const makeAxiosDeleteRequest = (url, data = null, headers) => makeAxiosRequest('DELETE', url, data, headers);

export const makeAxiosRequest = (type, url, data, headers = {}, responseType) => {
    const reqHeaders = { ...getDefaultHeaders(), ...headers };
    url = encodeUrl(url);
    return axios({
        method: type,
        responseType: responseType,
        url,
        headers: reqHeaders,
        data,
    });
};
