// import {
//     SET_AREA,
//     PAGE_CHANGED,
//     UPDATE_PAGE,
//     UPDATE_AREA,
//     UPDATE_CURRENT_PAGE,
//     UPDATE_MAIN_MENU,
//     UPDATE_TITLE,
//     UPDATE_SIDE_MENU,
//     UPDATE_BREADCRUMBS,
//     UPDATE_COMMANDBAR,
//     UPDATE_TABLE,
//     UPDATE_SELECTED_ITEMS,
//     UPDATE_CUSTOM_AREA
// } from './types';
import _ from 'lodash';
import { PORTAL_AREA } from '../constants';
import { deepCopy, deepMergeFlatten } from '../controls/forms/formFunctions';
import { getValueFromObjectUsingPath } from '../services/formDataServices';

const initialState = {
    area: PORTAL_AREA,
};

function rootReducer(state = initialState, action) {
    state = Object.assign({}, state, { eventType: action.type });

    if (action.type === 'SET_DASHBOARD_PROPERTIES') {
        return Object.assign({}, state, {
            dashboardProperties: action.payload.properties,
        });
    }

    if (action.type === 'SET_PAGE_PARAMETERS') {
        state = Object.assign({}, state, {
            pageParameters: { ...action.payload },
        });
        return state;
    }
    if (action.type === 'SET_INTELLISENSE') {
        state = Object.assign({}, state, {
            intellisense: action.payload.intellisense,
        });
        return state;
    }

    if (action.type === 'UPDATE_CONFIGS') {
        var { name, contents } = action.payload;
        const configs = state.configs;
        configs[name] = contents;
        var page = state.page;
        if (state.page.shortName === name) {
            // page = deepCopy(page);
            page.config = contents;
        }

        return Object.assign({}, state, {
            configs: configs,
            page: page,
        });
    }

    if (action.type === 'PORTAL_INITIALISED') {
        return Object.assign({}, state, {
            areas: action.payload.areas,
            configs: action.payload.configs,
            area: action.payload.area,
            cssFiles: action.payload.cssFiles,
        });
    }

    if (action.type === 'UPDATE_FORM') {
        state.form = null;
        return Object.assign({}, state, {
            form: action.payload.form,
        });
    }

    if (action.type === 'CONFIRM_FORM') {
        return Object.assign({}, state, {
            confirm: action.payload.confirm,
        });
    }
    if (action.type === 'SET_ALERT') {
        return Object.assign({}, state, {
            alert: {
                type: action.payload.type,
                title: action.payload.title,
                message: action.payload.message,
            },
        });
    }

    if (action.type === 'HIDE_ALERT') {
        return Object.assign({}, state, { alert: null });
    }

    if (action.type === 'SHOW_MODAL_SPINNER') {
        return Object.assign({}, state, {
            showModalSpinner: true,
            spinnerText: action.payload.text,
        });
    }

    if (action.type === 'HIDE_MODAL_SPINNER') {
        return Object.assign({}, state, {
            showModalSpinner: false,
        });
    }
    if (action.type === 'UPDATE_RESOURCES') {
        debugger;

        var settings = action.payload.settings;

        switch (settings.type.toLowerCase()) {
            case 'yaml':
                debugger;
                var configName =
                    settings.pageName === settings.areaName
                        ? settings.areaName
                        : `${settings.areaName.toLowerCase()}_${settings.pageName.toLowerCase()}`;

                state.configs[configName] = settings.contents;
                return Object.assign({}, state, {
                    configs: state.configs,
                });
            default:
                return state;
        }

        // return Object.assign({}, state, {
        //     showModalSpinner: false,
        // });
    }

    if (action.type === 'SET_ERROR') {
        if (action.payload.error) {
            var title = action.payload.title == null ? 'Uncaught Error' : action.payload.title;

            var error = '';
            try {
                error =
                    typeof action.payload.error?.response?.data != 'string'
                        ? JSON.stringify(action.payload.error?.response?.data)
                        : action.payload.error?.response?.data;
            } catch {
                error = action.payload.error?.response?.data;
            }

            var message = action.payload.error.message + ': ' + error;
            if (action.payload.error.status === 404) {
                title = '404: Not found';
                message = `Unable to find api: ${action.payload.error.url}`;
            }

            return Object.assign({}, state, {
                eventType: 'SET_ALERT',
                alert: {
                    type: 'danger',
                    title: title,
                    message: message,
                    id: action.payload.error.id,
                },
            });
        } else {
            return Object.assign({}, state, {
                eventType: 'SET_ALERT',
                alert: null,
            });
        }
    }

    if (action.type === 'ALLOW_FORM_REDRAW') {
        return Object.assign({}, state, {
            stopFormGeneratorUpdating: false,
        });
    }

    if (action.type === 'SET_PAGE') {
        return Object.assign({}, state, {
            page: action.payload.page,
            allDataLoaded: false,
            confirm: null,
            form: null,
        });
    }

    if (action.type === 'ALL_DATA_LOADED') {
        return Object.assign({}, state, {
            eventType: 'ALL_DATA_LOADED',
            allDataLoaded: action.payload.allDataLoaded,
        });
    }

    if (action.type === 'SET_DATASTORE') {
        // TODO: THIS WAS REMOVED AS GOING TO UNDERWRITING PAGE FAILS TO SET TEH DATA STORE (DO NOT DELETE THIS COMMENT OR CODE)
        // if (action.payload.currentUrl !== state.currentLocation && state.currentLocation != null) {
        //     console.log(
        //         `--> Ignoring outdated data <-- Old page: ${state.currentLocation}, new Page: ${action.payload.currentUrl}`
        //     );
        //     return state;
        // }
        var ds = action.payload.dataStore;
        if (!action.payload.clean) {
            // search yaml files
            if (action.payload.mergeResult) ds = _.merge(state.dataStore, action.payload.dataStore);
            else ds = { ...state.dataStore, ...action.payload.dataStore };
            //
        }

        // Todo: Add throwIfNotFound
        // TODO REMOVE HACK TO
        window.dataStore = ds;

        // TODO: DEPRECATED use getValueFromObjectUsingPath directly
        ds.get = (key) => {
            if (key && key.indexOf('ds.') > -1) key = key.substring(3); // auto-correct user errors
            return getValueFromObjectUsingPath(ds, key);
        };

        var newState = Object.assign({}, state, {
            dataStore: ds,
            eventTime: Date.now(),
            // area: action.payload.page.area,
            // page: action.payload.page,
            stillLoading: action.payload.stillLoading,
            stopFormGeneratorUpdating: action.payload.stopFormGeneratorUpdating,
        });

        return newState;
    }

    if (action.type === 'SET_SELECTED_ITEMS') {
        var newState2 = Object.assign({}, state, { selectedItems: action.payload.items });
        return newState2;
    }

    // used as navlink creates page refresh

    if (action.type === 'PAGE_CHANGED') {
        if (state.currentLocation === action.payload.currentPage) return state;
        if (!action.payload.clearDataStore) {
            return Object.assign({}, state, {
                search: '',
                selectedItems: [],
                currentLocation: action.payload.currentPage,
                eventType: 'PAGE_CHANGED',
                form: null,
                alert: null,
            });
        }

        var system = state.dataStore.system || null;
        var durable = state.dataStore.durable || null;
        var retVal = Object.assign({}, state, {
            search: '',
            selectedItems: [],
            dataStore: {
                get: (key) => null,
                system,
                durable,
            },
            currentLocation: null,
            eventType: 'PAGE_CHANGED',
            form: null,
            alert: null,
        });

        retVal.dataStore.get = function () {
            return null;
        };
        return retVal;
    }

    return state;
}

export default rootReducer;
